import React from "react";
import ImageGallery from "react-image-gallery";
import { useTranslation } from "react-i18next";

export const Metal = (props) => {
  const { t } = useTranslation();

  const imagesGallery = [
    {
      original: "img/metal/metal-02-large.jpg",
      thumbnail: "img/metal/metal-02-small.jpg",
    },
    {
      original: "img/metal/metal-03-large.jpg",
      thumbnail: "img/metal/metal-03-small.jpg",
    },
    {
      original: "img/metal/metal-04-large.jpg",
      thumbnail: "img/metal/metal-04-small.jpg",
    },
    {
      original: "img/metal/metal-05-large.jpg",
      thumbnail: "img/metal/metal-05-small.jpg",
    },
    {
      original: "img/metal/metal-06-large.jpg",
      thumbnail: "img/metal/metal-06-small.jpg",
    },
    {
      original: "img/metal/metal-07-large.jpg",
      thumbnail: "img/metal/metal-07-small.jpg",
    },
    {
      original: "img/metal/metal-08-large.jpg",
      thumbnail: "img/metal/metal-08-small.jpg",
    },
    {
      original: "img/metal/metal-09-large.jpg",
      thumbnail: "img/metal/metal-09-small.jpg",
    },
    {
      original: "img/metal/metal-10-large.jpg",
      thumbnail: "img/metal/metal-10-small.jpg",
    },
    {
      original: "img/metal/metal-11-large.jpg",
      thumbnail: "img/metal/metal-11-small.jpg",
    },
    {
      original: "img/metal/metal-12-large.jpg",
      thumbnail: "img/metal/metal-12-small.jpg",
    },
    {
      original: "img/metal/metal-13-large.jpg",
      thumbnail: "img/metal/metal-13-small.jpg",
    },
    {
      original: "img/metal/metal-14-large.jpg",
      thumbnail: "img/metal/metal-14-small.jpg",
    },
    {
      original: "img/metal/metal-15-large.jpg",
      thumbnail: "img/metal/metal-15-small.jpg",
    },
  ];

  return (
    <div id="portfoliometal" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("Metal.title")}</h2>
          <span class="wht">
            <p>{t("Metal.subtitle")}</p>
          </span>
        </div>
        <div className="row">
          <ImageGallery items={imagesGallery} />
        </div>
      </div>
    </div>
  );
};
