export const Footer = (props) => {
  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2022 Hyperion Solutions{" "}
            <a href="http://http://www.hyperionway.gr/" rel="nofollow">
              Hyperion
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
