import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'gr',
  lng: 'gr',
  resources: {
    gr: {
      data: require('./locales/gr/data.json')
    },
    en: {
      data: require('./locales/en/data.json')
    }
  },
  ns: ['data'],
  defaultNS: 'data'
});

i18n.languages = ['gr', 'en'];

export default i18n;