import { useTranslation } from "react-i18next";

export const Services = (props) => {
  const { t, i18n } = useTranslation();

  const services = Object.values(
    i18n.t("Services.services", { returnObjects: true })
  );
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("Services.title")}</h2>
          <p>{t("Services.subtitle")}</p>
        </div>
        <div className="row">
          {services.map((social) => (
            <div className="col-md-4">
              {" "}
              <i className={social.icon}></i>
              <div className="service-desc">
                <h3>{social.name}</h3>
                <p>{social.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
