import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Structural } from "./components/structural";
import { Testimonials } from "./components/testimonials";
import { Footer } from "./components/footer";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import { Metal } from "./components/metal";
import { Wooden } from "./components/wooden";
import { Prokat } from "./components/prokat";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <div>
      <Navigation />
      <Header />
      <Features />
      <About />
      <Services />
      <Structural />
      <Wooden />
      <Metal />
      <Prokat />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
