import { useTranslation } from "react-i18next";
import React from "react";

export const About = (props) => {
  const { t, i18n, ready } = useTranslation();

  if (!ready) return "loading translations...";

  const why = Object.values(i18n.t("About.why", { returnObjects: true }));
  const why2 = Object.values(i18n.t("About.why2", { returnObjects: true }));

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img
              src="img/central_building.webp"
              className="img-responsive"
              alt=""
            />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{t("About.title")}</h2>
              <p>{t("About.paragraph")}</p>
              <h3>{t("About.subtitle")}</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {why.map((why) => (
                      <li key={why.toString()}>{why}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {why2.map((why2) => (
                      <li key={why2.toString()}>{why2}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
