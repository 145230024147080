import { useTranslation } from "react-i18next";

export const Features = (props) => {
  const { t } = useTranslation();

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>{t("Features.title")}</h2>
        </div>
        <div className="row">
          <div className="col-xs-6 col-md-3">
            {" "}
            <i className={t("Features.context.0.icon")}></i>
            <h3>{t("Features.context.0.title")}</h3>
            <p>{t("Features.context.0.text")}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            {" "}
            <i className={t("Features.context.1.icon")}></i>
            <h3>{t("Features.context.1.title")}</h3>
            <p>{t("Features.context.1.text")}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            {" "}
            <i className={t("Features.context.2.icon")}></i>
            <h3>{t("Features.context.2.title")}</h3>
            <p>{t("Features.context.2.text")}</p>
          </div>
          <div className="col-xs-6 col-md-3">
            {" "}
            <i className={t("Features.context.3.icon")}></i>
            <h3>{t("Features.context.3.title")}</h3>
            <p>{t("Features.context.3.text")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
