import { useTranslation } from "react-i18next";

export const Navigation = (props) => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  function openPPT() {
    const filePath = t("Espa.ppt");

    window.open(filePath, "_blank");
  }
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div className="edit-logo">
            <div>
              <img src="./img/energonlogo64.png" alt=""></img>
            </div>
            <a className="navbar-brand page-scroll" href="#page-top">
              {t("Navigation.logo")}
            </a>{" "}
          </div>
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                {t("Navigation.menu.0")} |
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                {t("Navigation.menu.1")} |
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                {t("Navigation.menu.2")} |
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                {t("Navigation.menu.3")} |
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                {t("Navigation.menu.4")} |
              </a>
            </li>
            <li>
              <ab className="espa-logo" onClick={openPPT}>
                <img
                  src={t("Espa.img")}
                  width="280px"
                  height="100%"
                  alt="espa_logo"
                ></img>
              </ab>
            </li>
            <div className="navbar-translation">
              <button
                className="btn-gr"
                onClick={changeLanguage}
                value="gr"
                variant="outlined"
              >
                GR
              </button>
              <button
                className="btn-en"
                onClick={changeLanguage}
                value="en"
                variant="contained"
              >
                EN
              </button>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  );
};
