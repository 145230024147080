import { useTranslation } from "react-i18next";

export const Contact = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{t("Contact.title")}</h2>
                <p>{t("Contact.subtitle")}</p>
              </div>
              <iframe
                width="100%"
                height="400"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                id="gmap_canvas"
                title="myframe"
                src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=%CE%9A%CE%B1%CE%B2%CE%AC%CF%86%CE%B7%209%20%20%CE%9B%CE%B1%CE%BC%CE%AF%CE%B1+(%CE%A5%CF%80%CE%BF%CE%BA%CE%B1%CF%84%CE%AC%CF%83%CF%84%CE%B7%CE%BC%CE%B1)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>{t("Contact.header")}</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i>{" "}
                  {t("Contact.main_address")}
                </span>
                {t("Contact.address")}
              </p>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i>{" "}
                  {t("Contact.main_branch")}
                </span>
                {t("Contact.branch")}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {t("Contact.email")}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={t("Contact.youtube")}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
