import React from "react";
import ImageGallery from "react-image-gallery";
import { useTranslation } from "react-i18next";
import "react-image-gallery/styles/css/image-gallery.css";

export const Structural = (props) => {
  const { t } = useTranslation();
  const imagesGallery = [
    {
      original: "img/home/home-01-large.jpg",
      thumbnail: "img/home/home-01-small.jpg",
    },
    {
      original: "img/home/home-02-large.jpg",
      thumbnail: "img/home/home-02-small.jpg",
    },
    {
      original: "img/home/home-03-large.jpg",
      thumbnail: "img/home/home-03-small.jpg",
    },
    {
      original: "img/home/home-04-large.jpg",
      thumbnail: "img/home/home-04-small.jpg",
    },
    {
      original: "img/home/home-05-large.jpg",
      thumbnail: "img/home/home-05-small.jpg",
    },
    {
      original: "img/home/home-06-large.jpg",
      thumbnail: "img/home/home-06-small.jpg",
    },
    {
      original: "img/home/home-07-large.jpg",
      thumbnail: "img/home/home-07-small.jpg",
    },
    {
      original: "img/home/home-08-large.jpg",
      thumbnail: "img/home/home-08-small.jpg",
    },
    {
      original: "img/home/home-09-large.jpg",
      thumbnail: "img/home/home-09-small.jpg",
    },
    {
      original: "img/home/home-10-large.jpg",
      thumbnail: "img/home/home-10-small.jpg",
    },
    {
      original: "img/home/home-11-large.jpg",
      thumbnail: "img/home/home-11-small.jpg",
    },
    {
      original: "img/home/home-12-large.jpg",
      thumbnail: "img/home/home-12-small.jpg",
    },
    {
      original: "img/home/home-13-large.jpg",
      thumbnail: "img/home/home-13-small.jpg",
    },
    {
      original: "img/home/home-14-large.jpg",
      thumbnail: "img/home/home-14-small.jpg",
    },
    {
      original: "img/home/home-15-large.jpg",
      thumbnail: "img/home/home-15-small.jpg",
    },
  ];

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("Structural.title")}</h2>
          <p>{t("Structural.subtitle")}</p>
        </div>
        <div className="row">
          <ImageGallery items={imagesGallery} />
        </div>
      </div>
    </div>
  );
};
