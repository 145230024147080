import React from "react";
import ImageGallery from "react-image-gallery";
import { useTranslation } from "react-i18next";

export const Wooden = (props) => {
  const { t } = useTranslation();
  const imagesGallery = [
    {
      original: "img/wood/wood-01-large.jpg",
      thumbnail: "img/wood/wood-01-small.jpg",
    },
    {
      original: "img/wood/wood-02-large.jpg",
      thumbnail: "img/wood/wood-02-small.jpg",
    },
    {
      original: "img/wood/wood-03-large.jpg",
      thumbnail: "img/wood/wood-03-small.jpg",
    },
    {
      original: "img/wood/wood-04-large.jpg",
      thumbnail: "img/wood/wood-04-small.jpg",
    },
    {
      original: "img/wood/wood-05-large.jpg",
      thumbnail: "img/wood/wood-05-small.jpg",
    },
    {
      original: "img/wood/wood-06-large.jpg",
      thumbnail: "img/wood/wood-06-small.jpg",
    },
    {
      original: "img/wood/wood-07-large.jpg",
      thumbnail: "img/wood/wood-07-small.jpg",
    },
    {
      original: "img/wood/wood-08-large.jpg",
      thumbnail: "img/wood/wood-08-small.jpg",
    },
    {
      original: "img/wood/wood-09-large.jpg",
      thumbnail: "img/wood/wood-09-small.jpg",
    },
    {
      original: "img/wood/wood-10-large.jpg",
      thumbnail: "img/wood/wood-10-small.jpg",
    },
    {
      original: "img/wood/wood-11-large.jpg",
      thumbnail: "img/wood/wood-11-small.jpg",
    },
    {
      original: "img/wood/wood-12-large.jpg",
      thumbnail: "img/wood/wood-12-small.jpg",
    },
  ];

  return (
    <div id="portfoliowooden" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("Wooden.title")} </h2>
          <p>{t("Wooden.subtitle")}</p>
        </div>
        <div className="row">
          <ImageGallery items={imagesGallery} />
        </div>
      </div>
    </div>
  );
};
