import { useTranslation } from "react-i18next";

export const Header = (props) => {
  const { t } = useTranslation();
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <div className="maintitle">
                  <div className="titleimg">
                    <img
                      src="./img/energonlogo256.png"
                      width="80%"
                      alt=""
                    ></img>
                  </div>
                  <h6>
                    {t("Header.title")}
                    <span></span>
                  </h6>
                </div>
                <p>{t("Header.paragraph")}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  {t("Header.btn")}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
