import React from "react";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";

export const Prokat = (props) => {
  const { t } = useTranslation();

  const imagesGallery = [
    {
      original: "img/booth/booth-01-large.jpg",
      thumbnail: "img/booth/booth-01-small.jpg",
    },
    {
      original: "img/booth/booth-02-large.jpg",
      thumbnail: "img/booth/booth-02-small.jpg",
    },
    {
      original: "img/booth/booth-03-large.jpg",
      thumbnail: "img/booth/booth-03-small.jpg",
    },
    {
      original: "img/booth/booth-04-large.jpg",
      thumbnail: "img/booth/booth-04-small.jpg",
    },
    {
      original: "img/booth/booth-05-large.jpg",
      thumbnail: "img/booth/booth-05-small.jpg",
    },
    {
      original: "img/booth/booth-06-large.jpg",
      thumbnail: "img/booth/booth-06-small.jpg",
    },
    {
      original: "img/booth/booth-07-large.jpg",
      thumbnail: "img/booth/booth-07-small.jpg",
    },
    {
      original: "img/booth/booth-08-large.jpg",
      thumbnail: "img/booth/booth-08-small.jpg",
    },
    {
      original: "img/booth/booth-09-large.jpg",
      thumbnail: "img/booth/booth-09-small.jpg",
    },
    {
      original: "img/booth/booth-10-large.jpg",
      thumbnail: "img/booth/booth-10-small.jpg",
    },
    {
      original: "img/booth/booth-12-large.jpg",
      thumbnail: "img/booth/booth-12-small.jpg",
    },
    {
      original: "img/booth/booth-13-large.jpg",
      thumbnail: "img/booth/booth-13-small.jpg",
    },
    {
      original: "img/booth/booth-14-large.jpg",
      thumbnail: "img/booth/booth-14-small.jpg",
    },
    {
      original: "img/booth/booth-15-large.jpg",
      thumbnail: "img/booth/booth-15-small.jpg",
    },
  ];

  return (
    <div id="portfolioprokat" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("Prokat.title")}</h2>
          <p>{t("Prokat.subtitle")}</p>
        </div>
        <div className="row">
          <ImageGallery items={imagesGallery} />
        </div>
      </div>
    </div>
  );
};
